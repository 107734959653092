import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { withTranslation } from 'server/i18n'
import ArrowAltIcon from 'public/icons/arrow-alt.svg'
import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import BuyManageSell from 'components/BuyManageSell'

import style from './HomepageHero.style'

const HomepageHeroStyled = styled.div(props => ({ ...style(props) }))

const HomepageHero = ({ t }) => {
    return (
        <HomepageHeroStyled>
            <video
                src="https://res.cloudinary.com/merkandmerk/video/upload/v1742808265/website-video-header_cxuaab.mp4"
                className="Homepage-video"
                autoPlay
                loop
                muted
                playsInline
            />
            <div className="Homepage-content">
                <PageTitle
                    title={t('homepage-title')}
                    subtitle={t('homepage-subtitle')}
                    titleTag="h1"
                    subtitleTag="h2"
                    isHomepage
                />
                <div className="Homepage-cta">
                    <Button blue link="/inquiry" hasIcon variation="quaternary rounded inquiry-navigation">
                        {t('contact-us')} <ArrowAltIcon />
                    </Button>
                    <BuyManageSell />
                </div>
            </div>
        </HomepageHeroStyled>
    )
}

HomepageHero.propTypes = {
    t: PropTypes.func.isRequired
}
export default withTranslation('common')(withTheme(HomepageHero))
